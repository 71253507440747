<template>
  <div class="role">
    <!-- EditHead -->
    <EditHead :headName="['角色列表']" @handleRoute="handleRoute"></EditHead>
    <!-- increment role -->
    <div class="increment">
      <el-form :model="incrementForm" inline :rules="rules" ref="incrementForm">
        <el-form-item label="角色名称" prop="role_name">
          <el-input v-model="incrementForm.role_name"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" prop="desc">
          <el-input v-model="incrementForm.desc"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <!-- permission list -->
    <div class="permission" v-for="item in menuList" :key="item.id">
      <!-- indeterminate -->
      <div class="indeter" v-if="item.menu_type == 1">
        <el-checkbox :indeterminate="pMap[item.id.toString()] == 1" :value="pMap[item.id.toString()] == 2"
          @change="handleCheckedChange($event, item)">{{ item.name }}</el-checkbox>
      </div>
      <!-- permission List -->
      <div class="per_box" v-if="item.menu_type == 1">
        <!-- table -->
        <el-table :data="item.menu_child || []" style="width: 100%;margin-bottom: 20px;" row-key="id" border
          default-expand-all :show-header="false" :tree-props="{ children: 'menu_child', hasChildren: 'hasChildren' }">
          <el-table-column width="222">
            <template slot-scope="scope">
              <el-checkbox :indeterminate="pMap[scope.row.id.toString()] == 1" :value="pMap[scope.row.id.toString()] == 2"
                @change="handleCheckedChange($event, scope.row)">{{ scope.row.name }}</el-checkbox>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-descriptions class="margin-top" :column="1" labelClassName="label_class_name"
                contentClassName="content_class_name">
                <el-descriptions-item>
                  <!-- content -->
                  <el-checkbox v-for="ite in  (scope.row.child) || []" :label="ite.id" :key="ite.id"
                    :value="pMap[ite.id.toString()] == 2" @change="handleCheckedChange($event, ite)">{{ ite.name
                    }}</el-checkbox>
                </el-descriptions-item>
              </el-descriptions>
            </template>
          </el-table-column>
        </el-table>

      </div>
    </div>
    <!-- footer -->
    <diy-footer-button v-if="queryId ? hasPerm(['admin.role.update']) : hasPerm(['admin.role.store'])">
      <el-button type="primary" @click="handleSave">保存</el-button>
    </diy-footer-button>

  </div>
</template>
<script>
import { Message } from 'element-ui';

export default {
  data() {
    return {
      incrementForm: {},//增加角色信息对象
      permissionList: [],//权限列表
      menuList: [],
      pChild: {},
      pMap: {},
      cgp: {},
      indeterminateTitle: true,
      rules: {
        role_name: [{ required: true, message: "请输入角色名称!", trigger: ['blur'] }],
        desc: [{ required: true, message: "请填写角色描述信息!", trigger: ['blur'] }],
      },
      queryId: this.$route.query.id,
    }
  },
  created() {
    this.getMeNuList();
  },
  mounted() {
  },
  watch: {

  },
  methods: {
    // 将页面数据children改为child,防止table-tree递归渲染
    mapChildren2child(array) {
      if (!Array.isArray(array)) {
        throw new Error("'array' is not an array type");
      }
      let result = array.map(item => {
        if ((item.hasOwnProperty('children') && Array.isArray(item.children))) {
          let child = [];
          let menu_child = [];
          for (const i of this.mapChildren2child(item.children)) {
            if (i.menu_type == 2) {
              child.push(i);
            } else if (i.menu_type == 1) {
              menu_child.push(i);
            }
          }
          if (item.menu_type == 1 || item.menu_type == 2) {
            return {
              name: item.permission_name,
              id: item.id,
              menu_type: item.menu_type,
              child: child,
              menu_child: menu_child,
              hasChecked: false
            };
          }
          return {};
        }
      })
      return result;
    },
    returnCitems(arr) {
      let items = [];
      for (let i in arr.child) {
        items.push(arr.child[i]);
        items = items.concat(this.returnCitems(arr.child[i]));
      }
      for (let i in arr.menu_child) {
        items.push(arr.menu_child[i]);
        items = items.concat(this.returnCitems(arr.menu_child[i]));
      }
      return items;
    },
    formatChild(arr) {
      let map = {};
      for (let i in arr) {
        let idstr = arr[i].id.toString();
        map[idstr] = this.returnCitems(arr[i]);
        map = Object.assign({}, map, this.formatChild(arr[i].child), this.formatChild(arr[i].menu_child));

      }
      return map;
    },
    formatMap(arr) {
      let map = {};
      for (let i in arr) {
        let idstr = arr[i].id.toString();
        map[idstr] = 0;
        map = Object.assign({}, map, this.formatMap(arr[i].child), this.formatMap(arr[i].menu_child));
      }
      return map;
    },
    // 获取权限列表
    async getMeNuList() {
      const { data: { list }, code, message } = await this.$get(this.$apis.meNu);
      if (code != 200) {
        return Message.error(`${message}`);
      }
      // 需要做格式转换
      const result = this.mapChildren2child(list);
      //console.log('aaaaa', result, this.formatChild(result));
      this.$set(this, 'pChild', this.formatChild(result));
      this.$set(this, 'pMap', this.formatMap(result));
      this.$set(this, 'menuList', result);
      if (this.$route.query.id) {
        this.$get(`${this.$apis.ingleRole}/${this.$route.query.id}`).then(res => {
          if (res.code == 200) {
            this.$set(this, 'incrementForm', res.data);
            let plist = res.data.permissions;
            for (let pid in plist) {
              //this.pMap[plist[pid].toString()] = 2;
              this.$set(this.pMap, plist[pid].toString(), 2);
              let id = plist[pid].toString();
              for (let i in this.pChild) {
                let f_arr = [];
                let t_arr = [];
                let find = false;
                for (let ii in this.pChild[i]) {
                  if (this.pMap[this.pChild[i][ii].id.toString()] == 0) {
                    f_arr.push(ii);
                  } else {
                    t_arr.push(ii);
                  }
                  if (this.pChild[i][ii].id == id) {
                    find = true;
                  }
                }
                if (find) {
                  //this.pMap[i] = 0;
                  //this.$set(this.pMap,i,0);
                  if (t_arr.length > 0 && f_arr.length > 0) {
                    // this.pMap[i] = 1;
                    this.$set(this.pMap, i, 1);
                  } else if (t_arr.length > 0 && f_arr.length == 0) {
                    //this.pMap[i] = 2;
                    this.$set(this.pMap, i, 2);
                  } else {
                  }
                }
              }
            }
            //this.$set(this, 'pMap', this.pMap);
            this.$forceUpdate();
          } else {
            Message.error(`${res.Message}`);
          }
        })
      }
    },
    // checkbox-perssion
    handleCheckedChange(value, row) {
      if (this.pChild[row.id.toString()].length > 0) {
        this.pChild[row.id.toString()].forEach(item => {
          this.handleCheckedChange(value, item)
        });
      }
      this.$nextTick(() => {
        this.$set(row, 'hasChecked', value);
        this.pMap[row.id.toString()] = value == true ? 2 : 0;
        this.$set(this, 'pMap', this.pMap);
        this.parentClickStatus(row.id, value);
        this.$forceUpdate();
      })
    },
    parentClickStatus(id, value) {
      if (!value) {//选中
        for (let ii in this.pChild[id.toString]) {
          this.pMap[ii] = 0;
        }
      }
      let check_ids = [];
      for (let i in this.pChild) {
        let f_arr = [];
        let t_arr = [];
        let find = false;
        for (let ii in this.pChild[i]) {
          if (this.pMap[this.pChild[i][ii].id.toString()] == 0) {
            f_arr.push(ii);
          } else {
            t_arr.push(ii);
          }
          if (this.pChild[i][ii].id == id) {
            find = true;
          }
        }
        if (find) {
          this.pMap[i] = 0;
          if (t_arr.length > 0 && f_arr.length > 0) {
            this.pMap[i] = 1;
          } else if (t_arr.length > 0 && f_arr.length == 0) {
            this.pMap[i] = 2;
          } else {
          }
          check_ids.push(i);
        }
      }
      for (let index in check_ids) {
        let i = check_ids[index];
        let f_arr = [];
        let t_arr = [];
        for (let ii in this.pChild[i]) {
          if (this.pMap[this.pChild[i][ii].id.toString()] == 0) {
            f_arr.push(ii);
          } else {
            t_arr.push(ii);
          }
        }
        this.pMap[i] = 0;
        if (t_arr.length > 0 && f_arr.length > 0) {
          this.pMap[i] = 1;
        } else if (t_arr.length > 0 && f_arr.length == 0) {
          this.pMap[i] = 2;
        } else {
        }
      }
      this.$set(this, 'pMap', this.pMap);
    },
    // 保存
    handleSave() {
      // TODO:是否需要做选择权限判断？
      let ids = [];
      for (let i in this.pMap) {
        if (this.pMap[i] == 2) {
          ids.push(parseInt(i));
        }
      }
      const obj = { ...this.incrementForm, permissions: ids }
      //return;
      this.$refs['incrementForm']['validate'](valid => {
        if (valid) {
          this[(() => this.$route.query.id ? '$put' : '$post')()](`${this.$route.query.id ? this.$apis.addRole + "/" + this.$route.query.id : this.$apis.addRole}`, obj).then(res => {
            if (res.code == 200) {
              Message.success(`${this.$route.query.id ? '编辑' : '添加'}角色成功!`);
              // 清空表单
              this.$refs['incrementForm']['resetFields']();
              // 返回
              this.$router.push("/setup/management");
            } else {
              Message.error(`${res.message}`)
            }
          })
        }
      })
    },
    // 跳转
    handleRoute() {
      this.$router.push("/setup/management");
    }
  },
}
</script>
<style scoped lang="less">
::v-deep .el-descriptions-row>td {
  border-bottom: 0 !important;
  border-right: 0 !important;
}
.role {

  .increment {
    width: 100%;
    height: 154px;
    background: #FFFFFF;
    border-radius: 3px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    // justify-content: space-evenly;
    align-items: center;

    & /deep/.el-form {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      & .el-form-item {
        margin: 0;

        & .el-form-item__content {
          width: 280px;
        }
      }
    }
  }

  .permission {
    width: 100%;
    background: #FFFFFF;
    border-radius: 3px;
    padding: 20px;
    box-sizing: border-box;
    margin: 10px 0;

    & .indeter {
      width: 100%;
      margin-bottom: 16px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }

    & .per_box {
      & /deep/.el-descriptions__header {
        margin: 0;
      }

      & /deep/.el-descriptions-item {
        padding: 0;
      }

      & /deep/.el-descriptions__body {
        background-color: transparent !important;
      }

      & /deep/.el-descriptions-row {
        background-color: transparent;
      }

      & /deep/.label_class_name {
        width: 222px;
        height: 54px;
        background: #FBFBFB;
        border: 1px solid #F2F2F2;
        display: none;
      }

      & /deep/.content_class_name {
        width: 100%;
        flex-wrap: wrap;
      }
    }
  }

  .addtion_foot {
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    position: fixed;
    bottom: 0;
    z-index: 2;
    left: 0;

    & .addtion_foot-body {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>